import React from 'react';
import { Link } from 'gatsby';
import { getAuthorUrl } from '../../model/author';

const AuthorListItem = ({ title, slug }) => (
  <Link to={getAuthorUrl(slug)} className="authors-grid__list-name">
    {title}
  </Link>
);

export default AuthorListItem;
