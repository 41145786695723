import React from 'react';
import parse from 'html-react-parser';
import TwitterIcon from '../../assets/svgs/twitter.svg';
import { Card as ArticleCard } from '../articles/card';
import { stripEntities } from '../../model/author';

const AuthorContentItem = ({ author }) => {
  const { title, slug, content, articles, tghpwipAuthorTwitterUrl: twitterUrl } = author;

  return (
    <div className="authors-grid__post" id={stripEntities(slug)}>
      <div className="authors-grid__post-title">
        <span>{title}</span>
      </div>
      <div className="authors-grid__post-content">
        {twitterUrl && (
          <a href={twitterUrl} className="authors-grid__post-twitter" target={`_blank`}>
            <TwitterIcon /> <span>Follow on Twitter</span>
          </a>
        )}
        <div className="authors-grid__post-excerpt">{parse(content)}</div>
      </div>
      {articles && (
        <div className="authors-grid__post-articles">
          {articles.map((article) => (
            <ArticleCard post={article} key={article.slug} />
          ))}
        </div>
      )}
    </div>
  );
};

export default AuthorContentItem;
