import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/layout';
import { HeadMeta } from '../components/layout/meta';
import AuthorListItem from '../components/authors/author-list-item';
import AuthorContentItem from '../components/authors/author-content-item';
import { getStickySortedAuthors } from '../model/author';
import classNames from 'classnames';

export default function AuthorsPage({
  data: {
    wpPage,
    allWpTghpAuthor: { nodes: allAuthors },
    allWpPost: { nodes: allPosts },
  },
}) {
  const [expanded, setExpanded] = useState(false);
  const expandButton = (
    <button
      className={`authors-grid__authors-list-toggle`}
      onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
    >
      {!expanded ? 'View' : 'Hide'} Author Quick-Links
    </button>
  );

  const sortedAuthorsList = getStickySortedAuthors(allAuthors, allPosts);

  return (
    <Layout location={'authors'} seoData={wpPage.seo} wpPage={wpPage}>
      <div className="authors-grid">
        {expandButton}
        <div
          className={classNames(['authors-grid__list', expanded && `authors-grid__list--expanded`])}
        >
          {sortedAuthorsList.map(({ title, slug, databaseId }) => (
            <AuthorListItem title={title} slug={slug} key={databaseId} />
          ))}
        </div>
        <div className="authors-grid__posts">
          {sortedAuthorsList.map((author) => (
            <AuthorContentItem author={author} key={author.databaseId} />
          ))}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      ...SeoData
    }

    allWpTghpAuthor {
      nodes {
        title
        slug
        content
        databaseId
        tghpwipAuthorTwitterUrl
        tghpwipAuthorStickyPost
      }
    }

    allWpPost {
      nodes {
        title
        uri
        slug
        date
        excerpt
        tghpwipArticleAuthor
        tghpwipArticleLabel
      }
    }
  }
`;

export const Head = () => <HeadMeta />;
